//#region 3 types of form. 
export const regFormType = {
    openAccount: 'openAccount',
    resumeApp: 'RESUME',
    trackApp: 'trackApp'
}
//#endregion

//#region reg form links. 
// export const regFormLinks = {
//     trackApplication: "https://ekycuat.motilaloswal.com/ekycverifier/trackapplication.aspx"
// }
//#endregion

export const userType = {
    direct: 'DIRECT',
    crm: 'CRM',//sathi -> branch
    pp: 'PP',//frenchise -> Uppermost -> ba
    alliance: 'alliance',
}

export const emailOTPText = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'

export const OTPLength = 4

export const bottomTextForTextFieldMass = "You will receive an instant OTP that need to be entered in Proof upload section. Also, all important communications will be sent on this email id."

export const bottomTextForTextField = "You will be required to enter the OTP sent on this Email ID for verification on the next page"

export const patternType = {
    alphaNumeric: 'alphaNumeric',
    alphabates: 'alphabates',
    numeric: 'numeric',
    float: 'float',
    line: 'line'
}


export const regEx = {
    panRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    // panRegex: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
    userIdRegex: /^([a-zA-Z]){1}[0-9]*$/,
    number: /^[0-9]*$/,
    numberWithSlash: /^[0-9/]*$/,
    mobileNumber: /^[6789]([0-9])*$/,
    otpRegex: /^[0-9]*$/,
    // email: /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+){0,1}\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i,
    // email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    // email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[cC]{1,1}[oO]{1,1}[mM]{1,1}))$/,
    email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
    alphaNumeric: /^[a-zA-Z0-9]*$/,
    alphaNumericWithDotSpace: /^[a-zA-Z0-9. ]*$/,
    alphaNumericWithSpace: /^[a-zA-Z0-9 ]*$/,
    alphabates: /^[a-zA-Z]*$/,
    float: /^\d+(\.\d+)?$/,
    // line: /^(?!\s)([a-zA-Z. ])*$/,
    line: /^(?!\s)([a-zA-Z ])*$/,
    ifscRegex: /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$/,
    dobRegex: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
}

export const documentKeyForProof = {
    PAN: 'PAN',

    BANKPROOFEQ: 'BANKPROOFEQ',//cancel cheque
    MARGINCHEQUEUPLOAD: 'MARGINCHEQUEUPLOAD',//at a time only one present

    INCOMEPROOFFO: 'INCOMEPROOFFO',

    AADHAR: 'AADHAR',//just for box
    AADHARFRONT: 'AADHAR',
    AADHARBACK: 'AADHAARBACK',
    CLIENTPHOTO: 'CLIENTPHOTO',
    PANSIGN: 'PANSIGN',// signature cropped from pancard
    CHEQUESIGN: 'CHEQUESIGN', // signature cropped from cancel cheque
    // CLIENTPHOTO: 'CLIENTPHOTO', // client photo cropped from aadhar
    POA: 'POA', //POA front
    ADDITIONAL: 'ADDITIONAL', //POA back
    NOMINEEWITNESSSIGN: 'NOMINEEWITNESSSIGN', //nominee witness signature
    NOMINEEWITNESSPAN: 'NOMINEEWITNESSPAN',
    CMRCOPY: 'CMRCOPY',
    DISCOPY: 'DISCOPY',
}

export const mobileProofTabs = {
    PAN: 'PAN',
    // PANSIGN: 'PANSIGN',
    BANKPROOFEQ: 'BANKPROOFEQ',
    AADHAR: 'AADHAR',
    INCOMEPROOFFO: 'INCOMEPROOFFO'
}

export const mobileProofTabSequence = [//not used
    {
        label: 'PAN', value: mobileProofTabs.PAN,
        documents: [documentKeyForProof.PAN]//documents to validate on next click
    },
    {
        label: 'Aadhar', value: mobileProofTabs.AADHAR,
        documents: [documentKeyForProof.AADHARFRONT, documentKeyForProof.AADHARBACK, documentKeyForProof.CLIENTPHOTO]
    },
    // {
    //     label: 'Cheque', value: mobileProofTabs.PANSIGN,
    //     documents: [documentKeyForProof.PANSIGN]
    // },
    {
        label: 'Cheque', value: mobileProofTabs.BANKPROOFEQ,
        documents: [documentKeyForProof.BANKPROOFEQ]
    },
    {
        label: 'Bank Statement', value: mobileProofTabs.INCOMEPROOFFO,
        // documents: []
        documents: [documentKeyForProof.INCOMEPROOFFO]
    },
];

export const proofStatusMapping = {
    "0": { label: 'Pending', meaning: 'Uploaded but not yet approved', className: 'verification-status-pending' },
    "1": { label: 'Rejected', meaning: 'Rejected', className: 'verification-status-rejected' },
    "2": { label: 'Approved', meaning: 'Approved', className: 'verification-status-approved' },
    "": { meaning: 'file not yet uploaded' }
}

export const esign = {
    generateOtp: 'Generate OTP',
    otpTitle: 'Aadhaar based esign (OTP)',
    otp: 'OTP',
    biometric: 'Biometric',
    emudhra: 'Offline signature',
    notAbleToEsign: 'Not able to perform e-Sign',
    consent: 'I confirm that I have gone through the pdf copy of eKYC form, terms & conditions and further confirm that all my information & details is captured properly therein, I also agree to authenticate and confirm to affix my e-signature on the application / eKYC form using Aadhaar / PAN through MOFSL-NSDL eSign Service. \n\nI allow MOFSL to send communication to me for any MO related offerings to me on my registered client code/mobile number through WhatsApp.',

    consentEmudra: 'I confirm that I have gone through the pdf copy of eKYC form, terms & conditions and further confirm that all my information & details is captured properly therein, I also agree to authenticate and confirm to affix my e-signature on the application / eKYC form using Aadhaar / PAN through MOFSL-NSDL / eMudhra eSign Service.\n\nI allow MOFSL to send communication to me for any MO related offerings to me on my registered client code/mobile number through WhatsApp.',


    content: 'Just affix your e-sign to authenticate all the details and get started on your investing journey.',
    ValidationMessages: {
        AcceptCondition: 'Please accept condition'
    },
    RequestBioButton: 'RequestBIOMETRIC',
    DownloadnCourierButton: 'DOWNLOADFORM&COURIER',
}

export const uploadProofType = {
    pan: 'pan',
    adharFront: 'adharFront',
    adharBack: 'adharBack',
    passportSizePhoto: 'passportSizePhoto',
    cheque: 'cheque',
    bankStatement: 'bankStatement',

}
export const dpIdMaxLength = 16;

export const proof = {
    pan: 'pan',
    adhar: 'adhar',
    cheque: 'cheque',
    bankStatement: 'bankStatement'
}

export const POAOptions = [
    {
        id: "1",
        label: "Yes"
    },
    {
        id: "2",
        label: "No"
    }
]

export const NomineeOptions = [
    {
        id: "3",
        label: "Yes"
    },
    {
        id: "4",
        label: "No"
    }
]

export const offerList = [
    {
        SchemeId: 1,
        SchemeName: "TestScheme",
        Amount: 100.0,
        Credits: 500.0

    },
    {
        SchemeId: 2,
        SchemeName: "TestScheme",
        Amount: 100.0,
        Credits: 500.0

    },
    {
        SchemeId: 3,
        SchemeName: "TestScheme",
        Amount: 100.0,
        Credits: 500.0
    },

]

export const redeem = [
    {

        offerblockheading: 'OFFER 1',
        offerblockCreditValue: '1000',
        offerblockpayV: 'Cashback worth',
        className: "active"

    },
    {
        offerblockheading: 'OFFER 2',
        offerblockCreditValue: '1000',
        offerblockpayV: 'Free online stock market course worth',
        className: "active"

    },
    {
        offerblockheading: 'OFFER 3',
        offerblockCreditValue: '1000',
        offerblockpayV: 'Amazon vouchers worth',
        className: "active"
    },

]

export const finishMessage = "You are on your way to your Wealth Creation journey. Redirecting to MOFSL Trading Portal"

export const apiErrorResponse = "We apologize for the inconvenience, unable to process your request. Please re try."

export const authorizationError = "Session Expired. Please login again."

export const mobileExistMessage = "Mobile Number already exists with us."

export const validImagedoc = "Only image files(.png, .jpg etc) allowed. Word docs not allowed"

export const billionaireQuotes = [
    {
        quote: "There's no bad time to innovate.",
        author: "Jeff Bezos"
    },
    {
        quote: "If past history was all there was to the game, the richest people would be librarians.",
        author: "Warren Buffett"
    },
    {
        quote: "Your most unhappy customers are your greatest source of learning.",
        author: "Bill Gates"
    },
    {
        quote: "When something is important enough, you do it even if the odds are not in your favor.",
        author: "Elon Musk"
    },
    {
        quote: "Especially in technology, we need revolutionary change, not incremental change.",
        author: "Larry Page"
    },
    {
        quote: "When something is important enough, you do it even if the odds are not in your favor.",
        author: "J. K. Rowling"
    },
    {
        quote: "The biggest risk is not taking any risk ... In a world that's changing really quickly, the only strategy that is guaranteed to fail is not taking risks.",
        author: "Mark Zuckerberg"
    },
    {
        quote: "I believe you have to be willing to be misunderstood if you're going to innovate.",
        author: "Jeff Bezos"
    },
    {
        quote: "Taxes are not good things, but if you want services, somebody's got to pay for them so they're a necessary evil.",
        author: "Michael Bloomberg"
    },
    {
        quote: "Of the billionaires I have known, money just brings out the basic traits in them. If they were jerks before they had money, they are simply jerks with a billion dollars.",
        author: "Warren Buffett"
    },
    {
        quote: "The only thing that makes sense is to strive for greater collective enlightenment.",
        author: "Elon Musk"
    },
    {
        quote: "If you're changing the world, you're working on important things. You're excited to get up in the morning.",
        author: "Larry Page"
    },
    {
        quote: "It is our choices ... that show what we truly are, far more than our abilities.",
        author: "J. K. Rowling"
    },
    {
        quote: "I think a simple rule of business is, if you do the things that are easier first, then you can actually make a lot of progress.",
        author: "Mark Zuckerberg"
    }
]

export const LinkConstant =
{
    DisclaimerLink: "https://www.motilaloswal.com/campaign/RegistrationOffers/page242/disclaimer-new.html",
    // TermsAndConditionsLink: "https://www.motilaloswal.com/campaign/RegistrationOffers/page242/terms-and-conditions-cb1.html",
    TermsAndConditionsLink: "https://www.motilaloswal.com/terms-and-conditions",
    Refer_N_Earn: "https://www.motilaloswal.com/refer-and-earn?utm_source=ProspectReferral&utm_medium=Button&utm_campaign=OpenDematAc",
    DownloadPOA: "https://ekyc.motilaloswal.com/ekyc_icons/poa.pdf",
    ekycLiveURL: "https://www.motilaloswal.com/open-demat-account/?SRC=Website&pd=Header_OAO_Button",
};
export const DeviceUrl =
{
    ANROID_URL: "https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en",
    IOS_URL: "https://apps.apple.com/in/app/motilal-oswal-trading-app/id1055577451",
    AndroidMoInvestorAppUrl: "https://play.google.com/store/apps/details?id=com.mosl.mobile&hl=en_IN&gl=US",
    IosMoInvestorAppUrl: "https://apps.apple.com/in/app/mo-investor/id1055577451",
    AndroidMoTraderAppUrl: "https://play.google.com/store/apps/details?id=mosl.powerapp.com&hl=en_IN&gl=US",
    IosMoTraderAppUrl: "https://apps.apple.com/in/app/mo-trader-share-trading-app/id1257014690"
};

export const primaryPurpose = [
    {
        title: 'Investment', name: 'Investment', label: 'pp-investment'
    },
    {
        title: 'Trading', name: 'Trading', label: 'pp-trading'
    },
    {
        title: 'Intraday', name: 'Intraday', label: 'pp-intraday'
    },
]

export const tradeSegmentNames = [
    {
        title: 'Equity', name: 'Equity', label: 'ts-equity', largeWidth: false
    },
    {
        title: 'Derivative, Commodity, Currency', name: 'Commodity', label: 'ts-commodity', largeWidth: true
    },
]

export const tradeSegment = [
    {
        name: 'All', label: 'ts-all'
    },
    {
        name: 'Equity', label: 'ts-equity'
    },
    {
        name: 'FnO', label: 'ts-fo'
    },
    {
        name: 'Commodity', label: 'ts-commodity'
    },
    {
        name: 'Currency', label: 'ts-currency'
    },
]

export const advisorOrDIYClient = [
    {
        title: 'Need Advisor', name: 'Need Advisor', label: 'ar-advisor'
    },
    {
        title: 'DIY Client', name: 'DIY Client', label: 'ar-diy'
    }
]

export const otherDematAcc = [
    {
        title: 'Yes', name: 'Yes', label: 'dmatyes'
    },
    {
        title: 'No', name: 'No', label: 'dmatno'
    }
]

export const maritalStatusArray = [
    {
        label: 'Unmarried'
    },
    {
        label: 'Married'
    },
]

export const politicallyArray = [
    {
        label: 'Yes'
    },
    {
        label: 'No'
    },
]

export const investmentArray = [
    {
        label: 'Less than 1 Yr'
    },
    {
        label: '1-2 Yrs'
    },
    {
        label: '2-5 Yrs'
    },
    {
        label: '5-10 Yrs'
    },
    {
        label: '10-20 Yrs'
    },
    {
        label: '20-25 Yrs'
    },
]

export const incomeArray = [
    {
        label: 'Below 1 Lac'
    },
    {
        label: '1-5 Lac'
    },
    {
        label: '5-10 Lac'
    },
    {
        label: '10-25 Lac'
    },
    {
        label: 'Above 25 Yrs'
    },
]

export const commodityArray = [
    {
        label: 'Farmer producer organiation'
    },
    {
        label: 'Value chain participants'
    },
    {
        label: 'Others'
    },
]

export const educationArray = [
    {
        label: 'Graduate'
    },
    {
        label: 'Post Graduate'
    },
    {
        label: 'Professional'
    },
    {
        label: 'Under Graduate'
    },
]
export const incomeProofArray = [
    {
        id: 'Salary Slip',
        label: 'Salary Slip'
    },
    {
        id: 'Form 16',
        label: 'Form 16'
    },
    {
        id: 'Bank statement of last 6 months',
        label: 'Bank statement of last 6 months'
    },
    {
        id: 'IT acknowledgement',
        label: 'IT acknowledgement'
    },
]

export const bankArray = [
    {
        label: 'Bank statement'
    },
    {
        label: 'Bank passbook'
    },
    {
        label: 'Cheque'
    }
]

export const occupationArray = [
    {
        label: 'Private Sector Service'
    },
    {
        label: 'Public Sector'
    },
    {
        label: 'Government Service'
    },
    {
        label: 'Business'
    },
    {
        label: 'Retired'
    },
    {
        label: 'Professional'
    },
    {
        label: 'Agriculturist'
    },
    {
        label: 'Housewife'
    },
    {
        label: 'Student'
    },
    {
        label: 'Forex Dealer'
    },
    {
        label: 'Others'
    },
]

export const relationArray = [
    {
        label: 'Brother'
    },
    {
        label: 'Sister'
    },
    {
        label: 'Father'
    },
    {
        label: 'Uncle'
    },
    {
        label: 'Mother'
    },
    {
        label: 'Spouse'
    },
    {
        label: 'Daughter'
    },
    {
        label: 'Son'
    },
    {
        label: 'Others'
    },
]

export const genderArray = [
    {
        label: 'Male'
    },
    {
        label: 'Female'
    }
]

export const addressSameAsPrimaryArray = [
    {
        id: 'Yes',
        label: 'Yes'
    },
    {
        id: 'No',
        label: 'No'
    }
]

export const stateOptions_1 = [
    {
        label: 'Maharashtra'
    },
    {
        label: 'MP'
    }
]

export const stateOptions_2 = [
    {
        label: 'UP'
    },
    {
        label: 'Hyderabad'
    }
]

export const stateOptions_3 = [
    {
        label: 'Delhi'
    },
    {
        label: 'AP'
    }
]

export const cityOptions_1 = [
    {
        label: 'solapur'
    },
    {
        label: 'pune'
    }
]

export const cityOptions_2 = [
    {
        label: 'Banglore'
    },
    {
        label: 'Hyderabad'
    }
]

export const cityOptions_3 = [
    {
        label: 'mumbai'
    },
    {
        label: 'kolhapur'
    }
]

export const AadhaarArray = [
    {
        id: 'No',
        label: 'No'
    },
    {
        id: 'Yes',
        label: 'Yes'
    },
]

export const schemeArray = [
    {
        id: 'Premium',
        label: 'Premium'
    },
    {
        id: 'Classic',
        label: 'Classic'
    },
    {
        id: 'Prime',
        label: 'Prime'
    },
]

export const dummyAuProofList = [
    {
        ACCOUNTNUMBER: "1111111111111111",
        CROPSIGNPATH: "1",
        DISPLAYNOTES: "Required for KYC as per SEBI norms",
        DISPLAYTEXT: "PAN Card",
        DOCUMENTKEY: "PAN",
        DOWNLOADPDFURL: "https://ekycuat.motilaloswal.com/EKYCVERIFIER/frmPDFDownload.aspx?key=",
        ECOPY: "False",
        ECOPYMESSAGE: "VIEW UPLOADED FILE",
        ENABLEVERIFICATION: "1",
        FILEPATH: "",
        FILETYPE: "",
        IFSC: "SBIN0020924",
        IS70YEAROLD: "0",
        ISAADHAARXML: "0",
        ISNOMINEEPDF: "0",
        ISOTPSELECTED: "0",
        ISPANOPOPUP: "0",
        MANDATORY: "1",
        NAMEONDOC: "",// "SAMEER KISAN AVHAD",
        PDFFILEPATH: "",
        PROOFSTATUS: "",
        SAMPLEURL: "https://ekycuat.motilaloswal.com/ekycverifier/images/SampleProofs/PAN.png",
        SRNO: "1",
        SUBPROPERTIES: null,
        TOKENKEY: "44898",
        USERID: "1764701",
        VERIFIERREJECTREASON: "",
    },
    {
        ACCOUNTNUMBER: "1111111111111111",
        CROPSIGNPATH: "1",
        DISPLAYNOTES: "Upload clear image of Aadhar copy with masked first 8 digit i.e xxxx xxxx 1234",
        DISPLAYTEXT: "Aadhar Front (color Copy)",
        DOCUMENTKEY: "ADHARFRONT",
        DOWNLOADPDFURL: "https://ekycuat.motilaloswal.com/EKYCVERIFIER/frmPDFDownload.aspx?key=",
        ECOPY: "False",
        ECOPYMESSAGE: "VIEW UPLOADED FILE",
        ENABLEVERIFICATION: "1",
        FILEPATH: "",
        FILETYPE: "",
        IFSC: "SBIN0020924",
        IS70YEAROLD: "0",
        ISAADHAARXML: "0",
        ISNOMINEEPDF: "0",
        ISOTPSELECTED: "0",
        ISPANOPOPUP: "0",
        MANDATORY: "1",
        NAMEONDOC: "",//"SAMEER KISAN AVHAD",
        PDFFILEPATH: "",
        PROOFSTATUS: "",
        SAMPLEURL: "https://ekycuat.motilaloswal.com/ekycverifier/images/SampleProofs/PAN.png",
        SRNO: "1",
        SUBPROPERTIES: null,
        TOKENKEY: "44898",
        USERID: "1764701",
        VERIFIERREJECTREASON: "",
    },
    {
        ACCOUNTNUMBER: "1111111111111111",
        CROPSIGNPATH: "1",
        DISPLAYNOTES: "Upload clear image of Aadhar copy with masked first 8 digit i.e xxxx xxxx 1234",
        DISPLAYTEXT: "Aadhar Back (color Copy)",
        DOCUMENTKEY: "ADHARBACK",
        DOWNLOADPDFURL: "https://ekycuat.motilaloswal.com/EKYCVERIFIER/frmPDFDownload.aspx?key=",
        ECOPY: "False",
        ECOPYMESSAGE: "VIEW UPLOADED FILE",
        ENABLEVERIFICATION: "1",
        FILEPATH: "",
        FILETYPE: "",
        IFSC: "SBIN0020924",
        IS70YEAROLD: "0",
        ISAADHAARXML: "0",
        ISNOMINEEPDF: "0",
        ISOTPSELECTED: "0",
        ISPANOPOPUP: "0",
        MANDATORY: "1",
        NAMEONDOC: "",// "SAMEER KISAN AVHAD",
        PDFFILEPATH: "",
        PROOFSTATUS: "",
        SAMPLEURL: "https://ekycuat.motilaloswal.com/ekycverifier/images/SampleProofs/PAN.png",
        SRNO: "1",
        SUBPROPERTIES: null,
        TOKENKEY: "44898",
        USERID: "1764701",
        VERIFIERREJECTREASON: "",
    },
    {
        ACCOUNTNUMBER: "1111111111111111",
        CROPSIGNPATH: "1",
        DISPLAYNOTES: "Upload clear selfie without glasses or any other accessory",
        DISPLAYTEXT: "Photo (color Copy)",
        DOCUMENTKEY: "PHOTO",
        DOWNLOADPDFURL: "https://ekycuat.motilaloswal.com/EKYCVERIFIER/frmPDFDownload.aspx?key=",
        ECOPY: "False",
        ECOPYMESSAGE: "VIEW UPLOADED FILE",
        ENABLEVERIFICATION: "1",
        FILEPATH: "",
        FILETYPE: "",
        IFSC: "SBIN0020924",
        IS70YEAROLD: "0",
        ISAADHAARXML: "0",
        ISNOMINEEPDF: "0",
        ISOTPSELECTED: "0",
        ISPANOPOPUP: "0",
        MANDATORY: "1",
        NAMEONDOC: "",//"SAMEER KISAN AVHAD",
        PDFFILEPATH: "",
        PROOFSTATUS: "",
        SAMPLEURL: "https://ekycuat.motilaloswal.com/ekycverifier/images/SampleProofs/PAN.png",
        SRNO: "1",
        SUBPROPERTIES: null,
        TOKENKEY: "44898",
        USERID: "1764701",
        VERIFIERREJECTREASON: "",
    },
    {
        ACCOUNTNUMBER: "1111111111111111",
        CROPSIGNPATH: "1",
        DISPLAYNOTES: "Upload clear image of your bank statement for last 6 months",
        DISPLAYTEXT: "Bank statement (for FNO only)",
        DOCUMENTKEY: "BANK",
        DOWNLOADPDFURL: "https://ekycuat.motilaloswal.com/EKYCVERIFIER/frmPDFDownload.aspx?key=",
        ECOPY: "False",
        ECOPYMESSAGE: "VIEW UPLOADED FILE",
        ENABLEVERIFICATION: "1",
        FILEPATH: "",
        FILETYPE: "",
        IFSC: "SBIN0020924",
        IS70YEAROLD: "0",
        ISAADHAARXML: "0",
        ISNOMINEEPDF: "0",
        ISOTPSELECTED: "0",
        ISPANOPOPUP: "0",
        MANDATORY: "1",
        NAMEONDOC: "",//"SAMEER KISAN AVHAD",
        PDFFILEPATH: "",
        PROOFSTATUS: "",
        SAMPLEURL: "https://ekycuat.motilaloswal.com/ekycverifier/images/SampleProofs/PAN.png",
        SRNO: "1",
        SUBPROPERTIES: null,
        TOKENKEY: "44898",
        USERID: "1764701",
        VERIFIERREJECTREASON: ""
    },
]

export const accountMinLength = 9;
export const accountMaxLength = 18;
export const ifscMinLength = 3;

export const aadhaarLength = 12;

export const dristhiPushDelayTime = 180000;

export const drishtiRemarks = {
    threeMinutes: "1",
    proofThankyou: "5",
    notAbletoEsign: "6"
}
export const tooltiptext = "If Aadhar is linked to your mobile number, you can activate your Trading account INSTANTLY through eSign with Mobile-based OTP";

export const aadhaarconsent = 'I/ We hereby submit voluntarily at my/our own discretion, the physical copy of Aadhaar card/physical e-Aadhaar / masked Aadhaar / offline electronic Aadhaar xml as issued by UIDAI (Aadhaar) / Aadhaar through digilocker, to Motilal Oswal Financial Services Ltd. (MOFSL) & hereby give my consent to MOFSL for verification of my/our Aadhaar to establish its genuineness through Quick Response (QR) code embedded in the Aadhaar card or fetch data from UIDAI or through any other acceptable manner as per UIDAI or under any Act or law from time to time for the purpose of establishing my identity.\nMOFSL has informed me that my Aadhaar details and identity information would only be used for demographic authentication, validation, KYC Verification purpose and that my biometrics will not be stored/shared and will be submitted to CIDR only for the purpose of authentication.I authorize MOFSL to link my Aadhaar number to my Trading / Demat Account, Biometric and/or One Time Pin (OTP) data (and/or any similar authentication mechanism) for Aadhaar based authentication for availing MOFSL services.\nI request that e-sign facility shall be extended to me once I/We have read and understood the complete Client Registration Form / RDD. I understand that this will enable me to complete authentication process to affix my signature on the application form using Aadhaar through esign Service.\nI understand that my information submitted to MOFSL shall not be used for any purpose other than mentioned above, or as per requirements of law.\nI understand that MOFSL shall ensure security and confidentiality of my personal identity data provided for the purpose of Aadhaar based authentication.\nI hereby give my consent to MOFSL for sharing my Aadhaar number mapped to my accounts with government agencies/ Stock Exchanges/ Depositories/ Clearing Corporation/ Registrars & Transfer Agents after authentication as per prevailing regulatory guidelines.';

export const LeadType = {
    ALL: 'ALL',
    INPROCESS: 'PENDING',
    COMPLETED: 'COMPLETED',
    VERIFICATION_PENDING: 'VerificationPending',
    APPROVAL: 'APPROVAL'
}

export const ClientType = {
    ALL: 'ALL',
    OWN: 'OWN',
    DIRECT: 'DIRECT',
    REFERRAL: 'REFERRAL',
}

export const DataTable = {
    ClientName: 'Client Name',
    Pan: 'PAN',
    Mobile: 'Mobile No.',
    Email: 'Email ID',
    Segment: 'Segment',
    ModifiedDate: 'Modified Date',
    CreatedBy: 'Created By',
    AccountForm: 'A/C Opening Form',
    View: 'View Form',
    Approve: 'Approve',
    Reject: 'Reject',
    CreateLogin: 'Create Login',
    exportToExcel: 'Export to Excel'
}

export const resumeForm = 'Resume Form'

export const viewForm = 'View Form'

export const Approve = '1'

export const Reject = '2'

export const SearchInputRegEx = /^(?!( |-|_|@|\.))[a-zA-Z0-9@-_ \.]+$/i