import VerificationApi from "../services/verificationApi";
import { apiErrorResponse, authorizationError } from '../helper/constant';
import { put } from 'redux-saga/effects'
import { INIT_SET_USER_RESPONSE, INIT_SET_USER_FAIL } from '../redux/types/user';
import { SET_LOADING, CLEAR_LOADING } from '../redux/types/loader';
import { setUser } from "../redux/actions/user";
import { RESPONSE_ERROR_SNACK_OPEN, RESPONSE_ERROR_POPUP } from '../redux/types/responseError'
import { push } from "connected-react-router";
import LogoutApi from '../services/logout'

let api = new VerificationApi();
let logoutApi = new LogoutApi();

export function* verifyOtpEmail({ type, payload, resolve, reject }) {
    try {
        let responsePayload = {};
        let response = yield api.verifyOtpEmail(payload)
        if (response && response.data.Status) {
            if (response.data.Data.Status === '1') {
                yield put(setUser({ isOtpRequired: false }))
            }
            resolve(response.data.Data);
        } else {
            yield put({ type: CLEAR_LOADING })
            responsePayload = { type: "error", message: response.data.Message }
            yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
        }
    } catch (e) {
        yield put({ type: CLEAR_LOADING })
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* getSchemeDetails({ type, payload, resolve, reject }) {
    try {
        let response = yield api.getSchemeDetails(payload)
        if (response.data && response.data.Status) {
            resolve(response.data.Data);
        } else {
            reject({ message: response.data.Message });
        }
    } catch (e) {
        yield put({ type: CLEAR_LOADING })
        let responsePayload = {};
        responsePayload = { type: "error", message: apiErrorResponse }
        yield put({ type: RESPONSE_ERROR_SNACK_OPEN, payload: responsePayload })
    }
}

export function* resendOtp({ type, payload, resolve, reject }) {
    try {
        let response = yield api.resendOtp(payload)
        console.log('response.data', response.data)
        console.log('response.data', response.data.Data)
        if (response.data && response.data.Status) {
            if (response.data.Data) {
                resolve(response.data.Data);
            } else {
                reject({ message: response.data.Data.Message });
            }
        }
        else {
            reject({ message: response.data.Message });
        }
    } catch (e) {
        reject({ message: apiErrorResponse })
    }
}

export function* initSetUser({ type, payload, resolve, reject }) {
    yield put({ type: SET_LOADING })
    try {
        let responsePayload = {};
        let output = yield api.initSetUser(payload)
        console.log('init response', output);
        yield put({ type: CLEAR_LOADING })
        let response = output.data || {};
        if (response && response.Status && response.Data && response.Data[0]) {
            let data = response.Data;
            responsePayload.id = data[0].USERID;
            responsePayload.fullName = data[0].USERNAME ? data[0].USERNAME : "";
            responsePayload.mobileNumber = data[0].PERSONALMOBILE !== "" ? data[0].PERSONALMOBILE : data[0].USERMOBILE;
            responsePayload.email = data[0].EMAIL;
            responsePayload.applicationType = data[0].APPLICATIONTYPE;
            responsePayload.accountType = data[0].ACCOUNT_TYPE;
            responsePayload.isOtpRequired = data[0].ISOTPAUTHENTICATED === "1" ? false : true;
            responsePayload.clientCode = data[0].CLIENTCODE;
            responsePayload.uniqueId = data[0].UNIQUEID ? data[0].UNIQUEID : "";
            responsePayload.isAuclient = data[0].ISAUCLIENT;
            responsePayload.isAubrefclient = data[0].ISAUBREFCLIENT ? data[0].ISAUBREFCLIENT : "";
            responsePayload.isBRBALogin = data[0].ISBRBALOGIN;
            responsePayload.paymentStatusEq = data[0].PAYMENT_STATUS_EQ;
            responsePayload.paymentStatusComm = data[0].PAYMENT_STATUS_COMM;
            responsePayload.offerpaymentcompleted = data[0].OFFERPAYMENTCOMPLETED;
            responsePayload.pageName = data[0].PAGENAME;
            responsePayload.showkycForm = data[0].SHOWKYCFORM;
            responsePayload.onlineAmountField = data[0].ONLINEAMOUNTFIELD;
            responsePayload.showPopUp = data[0].SHOWPOPUP;
            responsePayload.uniqueEmailId = data[0].UNIQUEIDEMAILID;
            responsePayload.isMassAccountBA = data[0].ISMASSACCOUNT_BA;
            responsePayload.isEmailUpdated = data[0].ISEMAILUPDATED;
            responsePayload.isPOARequired = data[0].ISPOAREQUIRED;
            responsePayload.isPOA = data[0].ISPOA;
            responsePayload.isAuSubtype = data[0].SHOWACCOUNTNUMBER;
            responsePayload.displayGender = data[0].DISPLAYGENDER;
            responsePayload.isTraderCodeShow = data[0].ISTRADERCODESHOW;
            responsePayload.isEsmartAuditVerificationPending = data[0].ISESMARTAUDITVERIFICATIONPENDING;
            responsePayload.isPennyDropFail = data[0].PENNYDROPFAIL;
            responsePayload.flashMsg = data[0].FLASHMESSAGE;
            responsePayload.footerLink = data[0].FOOTERLINK;
            responsePayload.valuepackPaymentCompleted = data[0].VALUEPACKPAYMENTCOMPLETED;
            responsePayload.isRetailBranch = data[0].ISRETAILBRANCH;
            responsePayload.isAmountValidationRequired = data[0].ISAMOUNTVALIDATIONREQUIRED;
            responsePayload.validationAmount = data[0].VALIDATIONAMOUNT;
            responsePayload.PaymentStatus = data[0].PAYMENTSTATUS;
            responsePayload.firstName = data[0].FIRSTNAME;
            responsePayload.isShowNominee = data[0].ISSHOWNOMINEE === "1" ? true : false;
            responsePayload.isShowFundOnCongratulation = data[0].ISSHOWFUNDONCONGRATULATION;
            responsePayload.lsqId = data[0].LSQLEADID;
            responsePayload.clientLogoUrl = data[0].CLIENTLOGOURL;
            responsePayload.cmrCopyRequired = data[0].CMRCOPYREQUIRED === "1" ? true : false;
            responsePayload.isPanEditable = data[0].ISPANEDITABLE
            responsePayload.isBankEditable = data[0].ISBANKEDITABLE;
            responsePayload.isEmailEditable = data[0].ISEMAILEDITABLE;
            responsePayload.isSubBrokerCodeShow = data[0].ISSUBBROKERCODESHOW;
            responsePayload.opportunityId = data[0].OPPORTUNITYID;
            responsePayload.isReferralShow = data[0].ISREFERRALREWARDSHOW;
            responsePayload.validatePanDobAsyncCall = data[0].VALIDATEPANDOB_ISASYNCCALL;
            responsePayload.isStaffClient = data[0].ISSTAFFCLIENT;
            responsePayload.isDisShow = data[0].ISDISSHOW;
            responsePayload.congratsMsg = data[0].CONGRATS_MSG;
            responsePayload.riskDisCloseLink = data[0].RISKDISCLOSELINK;
            responsePayload.show_cbp = data[0].SHOW_CBPFLAG;
            responsePayload.hideGoogleLogin = data[0].HIDEGOOGLELOGIN;
            responsePayload.isSegmentSelection = data[0].ISSEGMENTSELECTION;
            responsePayload.isMFCust = data[0].MFCUST;
            responsePayload.isEmailEditIconShow = data[0].ISEMAIL_EDIT_ICON_SHOW === "1" ? true : false;
            responsePayload.isEquityAfterMfd = data[0].EQUITY_AFTER_MFD;

            yield put({ type: INIT_SET_USER_RESPONSE, payload: responsePayload });
            resolve && resolve();
        } else {
            if (output.code === 401) {
                let res = yield logoutApi.logout(payload)
                console.log('res in init', res)
                responsePayload = { open: true }
                yield put({ type: RESPONSE_ERROR_POPUP, payload: responsePayload })
                yield put(push('/'));
            }
            yield put({ type: INIT_SET_USER_FAIL })
        }
    }
    catch (e) {
        console.log(e, 'error');
    }
}

export function* initSetUserSuperApp({ type, payload, resolve, reject }) {
    yield put({ type: SET_LOADING })
    try {
        let responsePayload = {};
        let output = yield api.initSetUserSuperApp(payload)
        console.log('initSetUserSuperApp response', output);
        yield put({ type: CLEAR_LOADING })
        let response = output.data || {};
        if (response && response.Status && response.Data && response.Data[0]) {
            let data = response.Data;
            responsePayload.pageName = data[0].PAGENAME;
            yield put({ type: INIT_SET_USER_RESPONSE, payload: responsePayload });
            resolve && resolve(response.Data[0]);
        } else {
            if (output.code === 401) {
                let res = yield logoutApi.logout(payload)
                console.log('res in init', res)
                responsePayload = { open: true }
                yield put({ type: RESPONSE_ERROR_POPUP, payload: responsePayload })
                yield put(push('/'));
            }
            yield put({ type: INIT_SET_USER_FAIL })
        }

    } catch (e) {
        console.log(e, "error-->")

    }
}

