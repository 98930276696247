import React, { Component } from 'react'
import RegHeader from '../../organism/regHeader'
import RegBanner from '../../organism/regBanner'
import RegForm from '../../organism/regForm'
import RegFeature from '../../organism/regFeature'
import RegProcedure from '../../organism/regProcedure'
import RegProcedureList from "../../organism/regProcedureList"
import RegCustomerReview from '../../organism/regCustomerReview'
import RegFAQs from '../../organism/regFAQs'
import RegNewCustomerReview from '../../organism/customerReview'
import RegInfo from '../../organism/regInfo'
import RegAccOpenInfo from '../../organism/regAccOpenInfo'
import RegInfoPage3 from '../../organism/regAccOpenInfo2'
import RegNewBanner from '../../organism/regMainBanner/index'
import RegReadyToInvest from '../../organism/regReadyToInvest'
import RegFooter from '../../organism/regFooter'
// import './index.css';
// import './registration.css'
// import './header.css'
// import './footer.css'
// import './form.css'
// import './owl.carousel.min.css'
import CustomerReviewByPJ from '../../organism/customerReviewByPJ'
import RegButton from '../../molecules/regButton'
import { Link } from 'react-router-dom'
import loadable from '@loadable/component'
import { connect } from 'react-redux'
import { setUser, getAuRmDetails } from '../../../redux/actions/user'
import { userType, regFormType, authorizationError, LinkConstant, apiErrorResponse } from '../../../helper/constant'
import queryString from 'query-string';
import { getUppercaseKeyObject, getBlogsDetails } from '../../../helper/method'
import { setHomePageQueryParams } from '../../../redux/actions/homePageQueryParams'
import classNames from 'classnames'
import Modal from '../../molecules/modal'
import ProceedButton from '../../molecules/proceedButton'
import { isMobile } from "react-device-detect";
import { TailSpin } from 'react-loader-spinner'
import { FaTimes } from 'react-icons/fa'
import $ from "jquery";
import SessionExpiredPopup from '../../organism/sessionExpiredPopup'
import reghome from '../../../assets/images/home.svg';
import Image from '../../atom/image';
import Steps from '../../organism/regSteps';
import RegBasicInfo from '../../organism/regBasicInfo';
import RegOpenAccountForm from '../../organism/regOpenAccountForm';
import RegMiddleForm from '../../organism/regOpenAccFormMiddle';
import RegBenifits from '../../organism/regShowBenifits';
import ShowInvestments from '../../organism/regShowInvestments';
import CustomerReviewSjet from '../../organism/regCustomerReviewSjet';
import SjetBlogs from '../../organism/regSjetBlogs';
import FAQsJet from '../../organism/regFAQsJet';
import StickyForm from '../../organism/stickyForm';
import offerBanner from '../../../assets/images/offerBanner.png';
import RegistrationApi from '../../../services/registrationApi';
import homeimg from '../../../assets/images/regscreen/home.png'
import RegSection1 from '../../organism/regSection1'
import RegSection2 from '../../organism/regSection2'
import RegSection3 from '../../organism/regSection3'
import RegSection4 from '../../organism/regSection4'
import RegSection5 from '../../organism/regSection5'
import RegSection6 from '../../organism/regSection6'
import RegSection7 from '../../organism/regSection7'
import RegSection8 from '../../organism/regSection8'
import mologo from '../../../assets/images/regscreen/mologo.png'
import RegSection9 from '../../organism/regSection9'
import RegSection10 from '../../organism/regSection10'
import RegSection11 from '../../organism/regSection11'
import RegSection12 from '../../organism/regSection12'
import RegSection13 from '../../organism/regSection13'

import MFDSection1 from '../../organism/mfdSection1'
import MFDSection2 from '../../organism/mfdSection2'
import MFDSection3 from '../../organism/mfdSection3'
import MFDSection4 from '../../organism/mfdSection4'
import MFDSection5 from '../../organism/mfdSection5'
import MFDSection6 from '../../organism/mfdSection6'
import MFDSection7 from '../../organism/mfdSection7'
import MFDSection8 from '../../organism/mfdSection8'
import MFDSection9 from '../../organism/mfdSection9'
import MFDSection10 from '../../organism/mfdSection10'
import MFDSection11 from '../../organism/mfdSection11'
import MFDSection12 from '../../organism/mfdSection12'
import MFDSection13 from '../../organism/mfdSection13'
import MFDSection14 from '../../organism/mfdSection14'
import MFDSection15 from '../../organism/mfdSection15'
import MFDSection16 from '../../organism/mfdSection16'
import MFDSection17 from '../../organism/mfdSection17'
import MFDSection18 from '../../organism/mfdSection18'
import MFDSectionFooter from '../../organism/mfdSectionFooter'


let api = new RegistrationApi();

class Registration extends Component {
    constructor(props) {
        super();
        this.state = {
            mobileOpenAccountButtonClick: false,
            open: false,
            showFullName: true,
            showMobile: true,
            showCity: true,
            openProPartnerPopup: false,
            showRegInfo: false,
            showRegInfo1: false,
            showRegInfo2: false,
            showRegInfo3: false,
            showRegInfo4: false,
            showRegInfo5: false,
            showRegInfo6: false,
            showRegInfo7: false,
            showRegInfo8: false,
            showRegInfo9: false,
            showRegInfo10: false,
            showRegInfo11: false,
            showRegInfo12: false,
            showRegInfo13: false,
            showRegInfo14: false,
            showRegInfo15: false,
            showRegInfo16: false,
            showRegInfo17: false,
            regFullName: '',
            regMobileNo: '',
            isSouthIndianSelected: false,
            showPartner: false,
            partnerCode: '',
            referralCode: '',
            selectedLanguage: '',
            getRegBlogsDetails: [],
            isMobileDevice: false
        }
    }

    //#region  Reading query parameters from registration link . And storing in redux store
    componentDidMount = async () => {
        // this.setState({
        //     showRegInfo: true,
        //     showRegInfo1: true,
        //     showRegInfo2: true,
        //     showRegInfo3: true,
        //     showRegInfo4: true,
        //     showRegInfo5: true,
        //     showRegInfo6: true,
        //     showRegInfo7: true,
        // })
        this.initScript()
        console.log("test123->", window.pageYOffset, "Hight->", window.innerHeight, "IWDTH->", window.innerWidth)

        // if (window.pageYOffset) {
        //     console.log("windowpageYOffset-->", window.pageYOffset,"windowpageX-->Offset", window.pageXOffset)
        // }
        console.log("test123-->", window.pageYOffset)
        // if (isMobile) {
        //     this.setState({ showRegInfo: true })
        // }

        // if (!isMobile) {
        //     setTimeout(() => {
        //         this.setState({
        //             showRegInfo: true,
        //             showRegInfo1: true,
        //             showRegInfo2: true,
        //             showRegInfo3: true,
        //         })
        //     }, 2000)   // 2 sec

        //     setTimeout(() => {
        //         this.setState({
        //             showRegInfo4: true,
        //             showRegInfo5: true,
        //             showRegInfo6: true,
        //             showRegInfo7: true,
        //         })
        //     }, 3000)   // 3 sec
        // }

        document.addEventListener("scroll", () => {
            if (window.pageYOffset > 290 || window.innerHeight > 600) {
                // if ($(window).width() < 767) {
                //     // alert("called1")
                //     // return;
                //     this.setState({ isMobileDevice: true })
                // } else {
                // alert("called2")
                this.setState({ showRegInfo: true })
                // }
            } if (window.pageYOffset > 50) {
                this.setState({ showRegInfo1: true })
            } if (window.pageYOffset > 700) {
                this.setState({ showRegInfo2: true })
            } if (window.pageYOffset > 1320) {
                this.setState({ showRegInfo3: true })
            } if (window.pageYOffset > 1781) {
                this.setState({ showRegInfo4: true })
            }
            // if (this.state.regMobileNo !== "") {
            //     if (window.pageYOffset > 1820) {
            //         this.setState({ showRegInfo4: true })
            //     }
            // }
            if (window.pageYOffset > 2300) {
                this.setState({ showRegInfo5: true })
            }
            // if (this.state.regMobileNo !== "") {
            //     if (window.pageYOffset > 2500) {
            //         this.setState({ showRegInfo5: true })
            //     }
            // }
            if (window.pageYOffset > 2600) {
                this.setState({ showRegInfo6: true })
            }
            // if (this.state.regMobileNo !== "") {
            //     if (window.pageYOffset > 3035) {
            //         this.setState({ showRegInfo6: true })
            //     }
            // }
            if (window.pageYOffset > 3200) {
                this.setState({ showRegInfo7: true })
            }
            // if (this.state.regMobileNo !== "") {
            //     if (window.pageYOffset > 3500) {
            //         this.setState({ showRegInfo7: true })
            //     }
            // } 
            if (window.pageYOffset > 3500) {
                this.setState({ showRegInfo8: true })
            }
            if (window.pageYOffset > 3800) {
                this.setState({ showRegInfo9: true })
            }
            if (window.pageYOffset > 4000) {
                this.setState({ showRegInfo10: true })
            }
            if (window.pageYOffset > 4100) {
                this.setState({ showRegInfo11: true })
            }
            if (window.pageYOffset > 4400) {
                this.setState({ showRegInfo12: true })
            }
            if (window.pageYOffset > 4700) {
                this.setState({ showRegInfo13: true })
            }
            if (window.pageYOffset > 5000) {
                this.setState({ showRegInfo14: true })
            }
            if (window.pageYOffset > 5300) {
                this.setState({ showRegInfo15: true })
            }
            if (window.pageYOffset > 5600) {
                this.setState({ showRegInfo16: true })
            }
            if (window.pageYOffset > 5900) {
                this.setState({ showRegInfo17: true })
            }

            if (process.env.REACT_APP_PUBLIC_URL == "/partner") {
                if (window.pageYOffset > 1620) {
                    this.setState({ showRegInfo3: true })
                    this.setState({ showRegInfo4: true })
                }
                if (window.pageYOffset > 2081) {
                    this.setState({ showRegInfo5: true })
                    this.setState({ showRegInfo6: true })
                    this.setState({ showRegInfo7: true })
                }
            }
        })
        console.log("test123-->", this.state.showRegInfo)
        //this type of import because cipher.js file contains window reference. And On server we don't have access.
        import('../../../lib/crypto/client').then(({ default: ParameterUtils }) => {
            this.parameterUtils = new ParameterUtils();
            let url = window.location.search;

            let params = {};

            if (url.indexOf('?') !== -1) {
                let queryStringFromUrl = window.location.search.split('?')[1];
                console.log('queryString', queryStringFromUrl)

                if (queryStringFromUrl.indexOf('diyid') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        diyId: params.diyid,
                    })
                };

                if (queryStringFromUrl.indexOf('diymass') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        diymass: params.diymass
                    })
                };

                if (queryStringFromUrl.indexOf('referralCode') !== -1) {
                    params = queryString.parse(queryStringFromUrl);
                    this.props.setUser({
                        referralCode: params.referralCode,
                    })
                };

                //#region utm campaign logic
                let utmParamsObj = queryString.parse(queryStringFromUrl);
                if (utmParamsObj) {
                    utmParamsObj = getUppercaseKeyObject(utmParamsObj);
                    if (utmParamsObj.SRC || utmParamsObj.PD || utmParamsObj.UTM_SOURCE || utmParamsObj.UTM_CAMPAIGN) {
                        this.props.setUser({
                            source: utmParamsObj.SRC || '',
                            sourceDetail: utmParamsObj.PD || '',
                            utmSource: utmParamsObj.UTM_SOURCE || '',
                            utmCampaign: utmParamsObj.UTM_CAMPAIGN || ''
                        })
                    }
                    if (utmParamsObj.APPLICATIONTYPE) {
                        this.props.setUser({
                            applicationType: utmParamsObj.APPLICATIONTYPE || ''
                        })
                    }
                    if (utmParamsObj.SRC === "Website") {
                        this.setState({ showCity: false })
                    }
                    if (utmParamsObj.SRC === "ProPartner" || utmParamsObj.SRC === "Pro-partner") {
                        this.setState({ openProPartnerPopup: true })
                    }
                    sessionStorage.setItem('lead_source_url', utmParamsObj.SRC);
                }
                //#endregion

                //    #region TABNAME/COMMANDTYPE logic
                let commandParamsObj = queryString.parse(queryStringFromUrl);
                if (commandParamsObj) {
                    commandParamsObj = getUppercaseKeyObject(commandParamsObj);
                    //if comes from sathi for resume app. then directly lands on resume app
                    if (commandParamsObj.COMMANDTYPE || commandParamsObj.DIYMASS) {
                        isMobile && this.setState({
                            mobileOpenAccountButtonClick: true
                        })
                        if (commandParamsObj.COMMANDTYPE == "RESUME") {
                            this.props.setUser({
                                applicationType: userType.direct,
                            })
                        }
                        this.props.setHomePageQueryParams({ formTypeCommingFromParams: commandParamsObj.COMMANDTYPE || commandParamsObj.DIYMASS });//3 types of form(Open new, resume application, track).
                        if (this.props.user.branchCode == "SIBMAIN" || this.props.user.branchCode == "sibmain" || this.props.user.source == "SIB Microsite" || this.props.user.source == "CBI Microsite" || this.props.user.isAlliance == "1") {
                            this.props.setUser({
                                applicationType: userType.crm,
                            })
                        }
                        return;
                    }
                }
                // #endregion

                params = this.parameterUtils.decrypt(queryStringFromUrl);
                console.log(params, 'decrypt params');
            }

            if (!params) {
                params = queryString.parse(params);
            } else {
                params = queryString.parse(params);
            }
            console.log('b params', params);
            params = getUppercaseKeyObject(params);
            console.log('a params', params);
            if (params.APPLICATIONTYPE === userType.crm && params.ISAUSHADOWACCOUNTENABLE == 1) {
                this.props.getAuRmDetails({
                    RMCode: params.BRANCHEMPCODE
                })
            }
            console.log('params', params)
            this.props.setUser({
                applicationType: params.APPLICATIONTYPE ? params.APPLICATIONTYPE : this.props.user.applicationType ? this.props.user.applicationType : userType.direct,
                appLoginId: params.APPLOGINID,
                branchEmpCode: params.BRANCHEMPCODE,
                isDiy: params.ISDIY || "",
                branchCode: params.BRANCHCODE,
                intrestedIN: params.INTERESTED_IN
            })

            if (params) {
                //if comes from sathi for resume app. then directly lands on resume app
                if (params.TABNAME) {
                    this.props.setHomePageQueryParams({ formTypeCommingFromParams: params.TABNAME });//3 types of form(Open new, resume application, track).
                }
            }
        })

        // this.setState({
        //     open: this.props.popup.open
        // })

        if (this.props.popup.open) {
            $('.sessionExpired')[0].click(); // To open popup for desktop
            if ($(window).width() < 767) {  // To open bottomSheet
                $("body").addClass("sessionExpiredSlide open");
                $("#sessionExpired").removeClass('overlay');
            }
        }

        $('.popupcta').click(function () {
            $('body').removeClass('sessionExpiredSlide').removeClass('open').removeClass('overlay');
        });

        $('body').attr('id', 'home');

        // let getRegBlogsDetails = await getBlogsDetails();
        // console.log("getRegBlogsDetails=", getRegBlogsDetails);
        // this.setState({ getRegBlogsDetails: getRegBlogsDetails.DataSet.Table })

        // let data = {
        //     "userName": "admin",
        //     "password": "admin@123"
        // }
        // try {
        //     api.getAuthForBlogs(data).then(response => {
        //         console.log(' getAuthForBlogs response', response);
        //         // api.encryptionForBlogs().then(response => {
        //         //     console.log(' encryptionForBlogs response', response);
        //         // })
        //         if (response.data.code == 200) {
        //             api.getBlogsApi().then(response => {
        //                 console.log(' getBlogsApi response', response);
        //                 if (response.data && response.data.Success) {
        //                     this.setState({ getRegBlogsDetails: response.data.DataSet.Table })
        //                 } else {
        //                     this.props.setResponseErrorSnack({ type: 'error', message: response.data.Message });
        //                 }
        //             })
        //         }
        //         else {
        //             this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
        //         }
        //     })
        // } catch (error) {
        //     this.props.setResponseErrorSnack({ type: 'error', message: apiErrorResponse });
        // }
    }
    //#endregion

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.applicationType !== this.props.user.applicationType) {
            if (this.props.user.applicationType === userType.direct) {
                this.setState({ showCity: false })
            }
        }
    }


    initScript = () => {
        $(document).ready(function () {
            $(".accordion-head").click(function () {
                // Toggle the current section
                $('.accordion-head').removeClass('active');
                $(this).addClass("active").next(".accordion-content").slideToggle();

                // Toggle the rotate class on the image
                $(this).find("img").toggleClass("rotate");

                // Hide other sections and remove the rotate class from their images
                $(".accordion-content").not($(this).next()).slideUp();
                $(".accordion-head img").not($(this).find("img")).removeClass("rotate");
            });
        });

    }
    // initScript = () => {


    //     // $(document).ready(function () {

    //     //     //$('.inputs').keyup(function(){
    //     //     // if($(this).val().length==$(this).attr('maxlength'))
    //     //     //	$(this).next(':input').focus()
    //     //     // });

    //     //     $('.digit-group').find('input').each(function () {
    //     //         $(this).attr('maxlength', 1);
    //     //         $(this).on('keyup', function (e) {
    //     //             var parent = $($(this).parent());

    //     //             if (e.keyCode === 8 || e.keyCode === 37) {
    //     //                 var prev = parent.find('input#' + $(this).data('previous'));

    //     //                 if (prev.length) {
    //     //                     $(prev).select();
    //     //                 }
    //     //             } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
    //     //                 var next = parent.find('input#' + $(this).data('next'));

    //     //                 if (next.length) {
    //     //                     $(next).select();
    //     //                 } else {
    //     //                     if (parent.data('autosubmit')) {
    //     //                         parent.submit();
    //     //                     }
    //     //                 }
    //     //             }
    //     //         });
    //     //     });



    //     //     //  $(document).on('blur', 'input, textarea', function(){
    //     //     //    $('.fourbox').removeClass("last");
    //     //     //  });


    //     //     $('.slides').slick({
    //     //         dots: true,
    //     //         infinite: false,
    //     //         speed: 300,
    //     //         slidesToShow: 2,
    //     //         slidesToScroll: 2,
    //     //         autoplay: true,
    //     //         responsive: [
    //     //             {
    //     //                 breakpoint: 1024,
    //     //                 settings: {
    //     //                     slidesToShow: 2,
    //     //                     slidesToScroll: 1,
    //     //                     infinite: true,
    //     //                     dots: true
    //     //                 }
    //     //             },
    //     //             {
    //     //                 breakpoint: 600,
    //     //                 settings: {
    //     //                     slidesToShow: 2,
    //     //                     slidesToScroll: 2
    //     //                 }
    //     //             },
    //     //             {
    //     //                 breakpoint: 480,
    //     //                 settings: {
    //     //                     slidesToShow: 1,
    //     //                     slidesToScroll: 1
    //     //                 }
    //     //             }
    //     //         ]
    //     //     });

    //     //     $('.slick-next').html('❯');
    //     //     $('.slick-prev').html('❮');
    //     //     $('.slick-dots li button').html("");
    //     // });
    //     if ($(window).width() < 767) {
    //         $('.mobcta').click(function () {
    //             $("html, body").animate({ scrollTop: 0 }, "slow");
    //             $('.name').focus();
    //         });
    //     }

    //     $('.cta.tc').click(function () {
    //         $('body').addClass('open tc');
    //     });


    //     $('.close , .otherno').click(function () {
    //         $('body#home').removeClass('open').removeClass('mobileotp ').removeClass('noscroll').removeClass('tc');;
    //     });

    //     $('.cta.mobileotp').click(function () {
    //         $('body').addClass('open mobileotp noscroll');
    //         $('body').removeClass('tc');
    //     });

    //     // $("body").scroll(function () {
    //     //     alert("test")
    //     // });

    //     // $('.cta.dark.inline-block').click(function () {

    //     //     $('.readmoreblock').slideToggle();
    //     // });

    //     // $('#slidetoggle').click(function(){
    //     //     alert("test");
    //     // });
    // }

    handleClickOnOpenAccount = () => {
        this.setState({
            mobileOpenAccountButtonClick: true
        })
    }

    closeMobileForm = () => {
        this.setState({
            mobileOpenAccountButtonClick: false
        })
    }

    handleClosePopup = () => {
        this.setState({
            open: false
        })
    }

    closeProPartnerPopup = () => {
        this.setState({ openProPartnerPopup: false })
    }

    handleOkClick = () => {
        $('.popupcta')[0].click();
    }

    handleCurrentOTPForm = (fullName, mobileNo, isSouthIndianSelected, showPartner, partnerCode, selectedLanguage, referralCode) => {
        this.setState({ regFullName: fullName, regMobileNo: mobileNo, isSouthIndianSelected: isSouthIndianSelected, showPartner: showPartner, partnerCode: partnerCode, referralCode: referralCode, selectedLanguage: selectedLanguage })
    }

    render() {

        console.log("condition", (this.state.showRegInfo3 && ((!this.props.user.applicationType === userType.crm) && (!this.props.user.applicationType === userType.pp))))
        console.log("condition", this.props.user.applicationType, userType)
        console.log("condition", ((!this.props.user.applicationType === userType.crm) && (!this.props.user.applicationType === userType.pp)))
        return (
            <>
                {/* <RegHeader /> */}
                {/* <div class="tophead">
                    <div class="header">                    </div>
                    <div class="breadcrumb-wrapper">
                        <div class="container">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="https://www.motilaloswal.com/">
                                        <Image src={reghome} className="breadcrumb-icon" alt={''} width="" height="" />
                                    </a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Open Demat Account</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <section>
                        <div class="container">
                            <div class="msection">

                                <div class="left">
                                    {this.state.mobileOpenAccountButtonClick &&
                                        <i
                                            className={classNames("fa fa-times", "modal-close-icon", "reg-close")}
                                            onClick={this.closeMobileForm}
                                        >
                                        </i>}
                                    {!this.state.mobileOpenAccountButtonClick && <RegNewBanner
                                        isBranchUser={this.props.user.applicationType === userType.crm}
                                        isBaUser={this.props.user.applicationType === userType.pp}

                                    />}

                                    {!this.state.mobileOpenAccountButtonClick &&
                                        <>
                                            <RegInfo />
                                            {this.state.showRegInfo ? <RegAccOpenInfo /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                                            {this.state.showRegInfo ? this.state.showRegInfo1 ? <RegInfoPage3 /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                            {this.state.showRegInfo1 ? this.state.showRegInfo2 ? <RegProcedureList /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                        </>
                                    }


                                </div>

                                <div class="right">
                                    <RegForm
                                        formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                        isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                        showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                        showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                        showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                        showOTP={this.props.showOTP}
                                    />
                                </div>

                                <SessionExpiredPopup handleOkClick={this.handleOkClick} />

                                <Modal
                                    open={this.state.openProPartnerPopup}
                                    closeOnDocumentClick={false}
                                    lockScroll
                                    modal
                                >
                                    <div className="regPopup-msg">{"Sorry - This link has been disabled. Please click on “Continue” to proceed for account opening process."}</div>
                                    <div className="popup-btn">
                                        <a href={LinkConstant.ekycLiveURL}>
                                            <ProceedButton
                                                handleProceed={this.closeProPartnerPopup}
                                                label={'Continue'}
                                                className={'noMargin'}
                                            />
                                        </a>
                                    </div>
                                </Modal>

                            </div>
                        </div>
                    </section>

                    {!this.state.mobileOpenAccountButtonClick &&
                        <>
                            {this.state.showRegInfo2 ? this.state.showRegInfo3 ? <RegProcedure /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                            <div id="customerreview">
                                <div class="container">
                                    <div class="msection">
                                        <div class="left">

                                            {this.state.showRegInfo3 ? this.state.showRegInfo4 ? <RegNewCustomerReview /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}
                                            {this.state.showRegInfo4 ? this.state.showRegInfo5 ? <RegFAQs /> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div > */}

                {/* MFD Reg screen start */}

                {(this.props.showMFDReg || this.props.showMFDOtp) &&
                    <>
                        <div className="holder">
                            <div className="newlanding">
                                <header>
                                    <div className="container_new">
                                        <picture>
                                            <source srcSet="https://dbs7qpzv4mcv.cloudfront.net/mologo-png.webp" type="image/webp" />
                                            <img src="https://www.motilaloswal.com/img/mologo.png" alt="Motilal Oswal Logo" />
                                        </picture>
                                    </div>
                                </header>
                                <div className="container_new">
                                    <section className="formsection">
                                        <div className="dflex spacebetween mobswap gap40">
                                            <div className="d68 ">
                                                <div className="breadcrumb1 dflex ">
                                                    <a href="https://www.motilaloswal.com/"> <img src={homeimg} alt="" />
                                                        Home</a> <span> /</span>
                                                    {this.props.user.intrestedIN == "MFD" ? <a href="">Open Mutual Fund Account</a> : this.props.user.intrestedIN === "EQUITY_MFD" ? <a >Open Demat and Mutual Fund Account</a> : <a href="">Open Mutual Fund Account</a>}
                                                </div>
                                                <div className="mtop20"></div>

                                                <MFDSection1 />
                                                <MFDSection2 />
                                                {this.state.showRegInfo1 ? <MFDSection3 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {/* {this.state.showRegInfo3 ? <MFDSection4 /> : <TailSpin width={50} height={50} color="#eb6400" />} */}
                                                {this.state.showRegInfo3 ? <MFDSection5 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo4 ? <MFDSection6 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo5 ? <MFDSection7 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo6 ? <MFDSection8 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo7 ? <MFDSection9 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo8 ? <MFDSection10 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo9 ? <MFDSection11 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo10 ? <MFDSection12 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo11 ? <MFDSection13 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo12 ? <MFDSection14 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo13 ? <MFDSection15 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo14 ? <MFDSection16 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo15 ? <MFDSection17 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                                {this.state.showRegInfo16 ? <MFDSection18 /> : <TailSpin width={50} height={50} color="#eb6400" />}

                                            </div>
                                            <div className="d30">
                                                <div className="formbox">
                                                    <RegForm
                                                        formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                                        isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                                        showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                                        showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                                        showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                                        showOTP={this.props.showOTP}
                                                        middleFormFullName={this.state.regFullName}
                                                        middleFormMobile={this.state.regMobileNo}
                                                        handleCurrentOTPForm={this.handleCurrentOTPForm}
                                                        showMFDReg={this.props.showMFDReg}
                                                        showMFDOtp={this.props.showMFDOtp}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                {(this.props.showMFDReg || this.props.showMFDOtp) &&
                                    <MFDSectionFooter />
                                }
                            </div>
                        </div>
                    </>
                }


                {/* MFD Reg screen end */}

                {(this.props.showMFDReg == false || this.props.showMFDOtp == false) &&
                    <div className="holder">
                        <div className="newlanding">
                            <header>
                                <div className="container_new">
                                    <picture>
                                        <source srcSet="https://dbs7qpzv4mcv.cloudfront.net/mologo-png.webp" type="image/webp" />
                                        <img src="https://www.motilaloswal.com/img/mologo.png" alt="Motilal Oswal Logo" />
                                    </picture>
                                </div>
                            </header>
                            <div className="container_new">
                                <section className="formsection">
                                    <div className="dflex spacebetween mobswap">
                                        <div className="d66 ">
                                            <div className="breadcrumb1 dflex ">

                                                <a href="https://www.motilaloswal.com/"> <img src={homeimg} alt="" /> {""}Home</a> <span> /</span>
                                                <a >Open Demat Account</a>
                                            </div>
                                            <div className="mtop20"></div>
                                            <RegSection1
                                                isBranchUser={this.props.user.applicationType === userType.crm}
                                                isBaUser={this.props.user.applicationType === userType.pp} />

                                            {this.state.showRegInfo1 ? <RegSection2 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {(this.state.showRegInfo2 && this.props.user.applicationType === userType.direct) ? <RegSection4 /> : this.props.user.applicationType === userType.direct ? <TailSpin width={50} height={50} color="#eb6400" /> : ""}
                                            {this.state.showRegInfo2 ? < RegSection6 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo3 ? < RegSection7 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo4 ? < RegSection9 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo5 ? < RegSection5 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo6 ? < RegSection10 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo7 ? < RegSection11 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo8 ? < RegSection12 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {this.state.showRegInfo9 ? < RegSection13 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                            {/* {this.state.showRegInfo6 ? < RegSection3/> : <TailSpin width={50} height={50} color="#eb6400" />} */}
                                            {this.state.showRegInfo10 ? <RegSection8 /> : <TailSpin width={50} height={50} color="#eb6400" />}
                                        </div>
                                        <div className="d30">
                                            <div className="formbox">
                                                <RegForm
                                                    formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                                    isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                                    showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                                    showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                                    showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                                    showOTP={this.props.showOTP}
                                                    middleFormFullName={this.state.regFullName}
                                                    middleFormMobile={this.state.regMobileNo}
                                                    handleCurrentOTPForm={this.handleCurrentOTPForm}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/* <section>
                        <div id="demo"></div>
                        <div id="demo1"></div>
                        <div className="container">
                            <div className={!this.state.mobileOpenAccountButtonClick &&
                                this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" ? "msection" : "msecmicrosite"}>

                                {!this.state.mobileOpenAccountButtonClick &&
                                    this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                                    <RegNewBanner
                                        isBranchUser={this.props.user.applicationType === userType.crm}
                                        isBaUser={this.props.user.applicationType === userType.pp}

                                    />}
                                <div className="right">
                                    {/* <div className="mob mobileBanner">
                                    <img src={offerBanner} width="100%"/>
                                </div> */}
                            {/* <div className="nformbox">
                                        <RegForm
                                            formTypeCommingFromParams={this.props.formTypeCommingFromParams}
                                            isRegistrationLink={this.state.mobileOpenAccountButtonClick}
                                            showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                                            showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                                            showCity={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showCity}
                                            showOTP={this.props.showOTP}
                                            middleFormFullName={this.state.regFullName}
                                            middleFormMobile={this.state.regMobileNo}
                                            handleCurrentOTPForm={this.handleCurrentOTPForm}

                                        />
                                    </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </section> */}
                        </div>
                    </div>
                }
                {/* {this.state.showRegInfo ?  */}
                {/* {
                    !this.state.mobileOpenAccountButtonClick &&
                    (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                    <Steps />
                } */}
                {/* : this.state.isMobileDevice ? <Steps />
                        : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                {/* <section>
                    {this.state.showRegInfo1 ?
                        // {
                        !this.state.mobileOpenAccountButtonClick &&
                        (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                        <RegBasicInfo />
                        // }
                        :
                        !this.state.mobileOpenAccountButtonClick &&
                        (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" && <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                    {this.state.showRegInfo2 ?
                        // <>{this.state.regMobileNo === "" && 
                        // {
                        !this.state.mobileOpenAccountButtonClick &&
                        (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                        process.env.REACT_APP_PUBLIC_URL !== "/partner" &&
                        <RegMiddleForm
                            showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                            showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                            handleCurrentOTPForm={this.handleCurrentOTPForm}
                            regMobileNo={this.state.regMobileNo}
                            regFullName={this.state.regFullName}
                        />
                        // }
                        //</> 
                        :
                        !this.state.mobileOpenAccountButtonClick &&
                        (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" && <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                    {
                        !this.state.mobileOpenAccountButtonClick &&
                        (this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain") && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                        <div className="container">
                            <div class="mtop40"></div>
                            {this.state.showRegInfo3 ?
                                <RegBenifits />
                                : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                            {this.state.showRegInfo4 ?
                                <ShowInvestments />
                                : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                            <div className="text-center">
                                {this.state.showRegInfo5 ?
                                    <CustomerReviewSjet />
                                    : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                                {
                                    this.state.showRegInfo6 && process.env.REACT_APP_PUBLIC_URL !== "/partner"
                                        ?
                                        <SjetBlogs getRegBlogsDetails={this.state.getRegBlogsDetails} />
                                        : this.state.showRegInfo6 && process.env.REACT_APP_PUBLIC_URL !== "/partner" && <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>
                                }
                                {this.state.showRegInfo7 ?
                                    <FAQsJet />
                                    : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>}
                            </div>
                        </div>
                    }
                </section> */}


                {/* {
                    !this.state.mobileOpenAccountButtonClick && this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&

                        this.props.user.applicationType == userType.crm || this.props.user.applicationType == userType.pp ?
                        <div className='MTF_TC'>
                            <a href='https://www.motilaloswal.com/campaign/MTF/MTF_TnC.pdf' target='_blank'><sup>#</sup>MTF Offer T&C</a>
                        </div>
                        :
                        null
                } */}

                {/* <div class="mtop40"></div> */}
                {/* {this.state.showRegInfo1 ?
                    <>
                        {this.state.regMobileNo === "" && */}
                {/* {
                    !this.state.mobileOpenAccountButtonClick && this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                    process.env.REACT_APP_PUBLIC_URL !== "/partner" &&
                    <StickyForm
                        showFullName={this.props.user.strPSource == "LIVEPHOTO" ? false : this.state.showFullName}
                        showMobile={this.props.user.strPSource == "LIVEPHOTO" ? true : this.state.showMobile}
                        handleCurrentOTPForm={this.handleCurrentOTPForm}
                        regMobileNo={this.state.regMobileNo}
                        regFullName={this.state.regFullName}
                    />
                } */}
                {/* }
                    </> : <div className="render-component"><TailSpin width={50} height={50} color="#eb6400" /></div>} */}
                {/* {
                    !this.state.mobileOpenAccountButtonClick && this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                    <div class="mobcta mob">
                        <a href="#" class="cta dark">
                            Open Demat Account
                        </a>
                    </div>
                } */}

                {
                    !this.state.mobileOpenAccountButtonClick && this.props.user.branchCode !== "SIBMAIN" && this.props.user.branchCode !== "sibmain" && this.props.user.source !== "SIB Microsite" && this.props.user.source !== "CBI Microsite" && this.props.user.isAlliance !== "1" &&
                    (this.props.showMFDReg == false || this.props.showMFDOtp == false) &&
                    <RegFooter />
                }
                {/* <a href="#sessionExpired" className="sessionExpired d-none">sessionExpiredPopup</a> */}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(setUser(data)),
    getAuRmDetails: (data) => dispatch(getAuRmDetails(data)),
    setHomePageQueryParams: (data) => dispatch(setHomePageQueryParams(data)),
})

const mapStateToProps = (state) => ({
    formTypeCommingFromParams: state.homePageQueryParams.formTypeCommingFromParams,
    user: state.user,
    popup: state.responseError.responseErrorPopup,
})


export default connect(mapStateToProps, mapDispatchToProps)(Registration)