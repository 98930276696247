import { v4 as uuidv4 } from 'uuid';
import { patternType, regEx } from "./constant";

export function isValidPANNo(panNo) {
    const panRegex = RegExp(regEx.panRegex);
    return panRegex.test(panNo)
}
export function isValidDob(dob) {
    const dobRegex = RegExp(regEx.dobRegex);
    return dobRegex.test(dob)
}
export function isValidIfscNo(ifscNo) {
    const panRegex = RegExp(regEx.ifscRegex);
    return panRegex.test(ifscNo)
}
export function isValidMobileNo(mobileNo) {
    const panRegex = RegExp(regEx.mobileNumber);
    return panRegex.test(mobileNo)
}
export function isValidName(name) {
    const panRegex = RegExp(regEx.line);
    return panRegex.test(name)
}
export function isValidEmail(email) {
    const panRegex = RegExp(regEx.email);
    return panRegex.test(email)
}
export function isValidOtp(otp) {
    const otpRegex = RegExp(regEx.otpRegex);
    return otpRegex.test(otp);
}
export function isValidAlphaNumeric(value) {
    const alphaNumericRegex = RegExp(regEx.alphaNumeric);
    return alphaNumericRegex.test(value);
}
export function isValidAlphaNumericWithDotSpace(value) {
    const alphaNumericRegex = RegExp(regEx.alphaNumericWithDotSpace);
    return alphaNumericRegex.test(value);
}
export function isValidAlphaNumericWithSpace(value) {
    const alphaNumericRegex = RegExp(regEx.alphaNumericWithSpace);
    return alphaNumericRegex.test(value);
}
export function isValidNumber(number) {
    const numberRegex = RegExp(regEx.number);
    return numberRegex.test(number)
}
export function isValidNumberWithSlash(number) {
    const numberRegex = RegExp(regEx.numberWithSlash);
    return numberRegex.test(number)
}
export function isValidAlphabates(value) {
    const alphaRegex = RegExp(regEx.alphabates);
    return alphaRegex.test(value);
}
export function isValidInteger(value) {
    const alphaRegex = RegExp(regEx.number);
    return alphaRegex.test(value);
}
export function isValidFloat(value) {
    const floatRegex = RegExp(regEx.float);
    return floatRegex.test(value);
}
export function isValidPattern(value, pattern) {
    switch (pattern) {
        case patternType.alphaNumeric:
            return isValidAlphaNumeric(value)
        case patternType.alphabates:
            return isValidAlphabates(value)
        case patternType.numeric:
            return isValidNumber(value)
        default:
            return true
    }
}
export function fetchWithWait({ dispatch, action }) {
    return new Promise((resolve, reject) => {
        dispatch({ ...action, resolve, reject })
    })
}

export function dpIdValididateCharByChar(value) {
    let decision = true;
    value = value.split("");
    for (let index = 0; index < value.length; index++) {
        let char = value[index];

        if (index < 2) {// first 2 values may letters or digits (first letter must b "I" n second letter must b "N")
            if ((index == 0 && char !== "i" && char !== "I") || (index == 1 && char !== "n" && char !== "N")) {
                if (isValidInteger(char)) {
                    decision = true;
                    // break;
                } else {
                    decision = false;
                    break;
                }
            }
        } else if (index >= 2 && index <= 15) {//remaining 14 values must b numeric
            if (!isValidInteger(char)) {
                decision = false;
                break;
            }
        }
    }
    return decision;
}

export function getUppercaseKeyObject(obj) {
    let newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key.toUpperCase()] = obj[key];
    })
    return newObj;
}
export function panValididateCharByChar(value) {
    let decision = true;
    value = value.split("");
    for (let index = 0; index < value.length; index++) {
        let char = value[index];
        if (index < 5 || index == 9) {// first 5 and last 10th is alphabetical
            if (!isValidAlphabates(char)) {
                decision = false;
                break;
            }
        } else if (index >= 5 && index <= 8) {//6-9 numeric
            if (!isValidInteger(char)) {
                decision = false;
                break;
            }
        }
    }
    return decision;
}
export function addDays(date, days) {
    var newDate = new Date(date.setTime(date.getTime() + days * 86400000));
    return newDate;
}
export function removeDays(date, days) {
    var newDate = new Date(date.setTime(date.getTime() - days * 86400000));
    return newDate;
}
export function bytesToMB(bytes) {
    if (bytes == 0) return 0;
    return bytes * 0.00000095367432;
}
export function isImageFile(fileType) {
    return (/(png)/gi.test(fileType) ||
        /(jpeg)/gi.test(fileType) ||
        /(jpg)/gi.test(fileType))
}
export function isPdfFile(fileType) {
    return (/(pdf)/gi.test(fileType))
}

export function isZipFile(fileType) {
    return (/(zip)/gi.test(fileType))
}
export function numberWithCommas(x) {
    if (x === '') {
        return '';
    }
    if (Number(x) === 0) {
        return 0;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getUniqueId = () => {
    return uuidv4();
}
export const getTime = () => {//number of milliseconds since midnight, January 1, 1970.
    let d = new Date();
    let n = d.getTime();
    return n;
}

export function getLocalIp(listnerFunc) { //  onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    let myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new myPeerConnection({
        iceServers: []
    }),
        noop = function () { },
        localIPs = {},
        ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    function iterateIP(ip) {
        if (!localIPs[ip]) listnerFunc(ip);
        localIPs[ip] = true;
    }
    // onNewIP
    //create a bogus data channel
    pc.createDataChannel("");
    // create offer and set local description
    pc.createOffer().then(function (sdp) {
        sdp.sdp.split('\n').forEach(function (line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }).catch(function (reason) {
        // An error occurred, so handle the failure to connect
    });
    //listen for candidate events
    pc.onicecandidate = function (ice) {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}
export function getPublicIp(listnerFunc) {
    // return fetch('https://geolocation-db.com/json/09ba3820-0f88-11eb-9ba6-e1dd7dece2b8')
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log('public data', data)
    //         listnerFunc(data)
    //     }
    //     )

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
    } else {
        console.log('Geolocation error=Geolocation is not supported by this browser.')
    }

    function showPosition(position) {
        console.log('public data', position.coords.latitude, position.coords.longitude)
        let data = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
        listnerFunc(data)
    }
}

export function getUser_City(info, listnerFunc) {
    // return fetch("https://api.ipstack.com/" + info + "?access_key=da7a03338f26b60b66c7db579c6d624c")
    return fetch("https://ipapi.co/" + info + "/json/")
        .then(response => response.json())
        .then(data => {
            console.log('city data', data)
            listnerFunc(data)
        }
        )
}
export function get_CityFromLatLong(LAT, LNG, listnerFunc) {
    // return fetch("https://api.ipstack.com/" + info + "?access_key=da7a03338f26b60b66c7db579c6d624c")
    return fetch("https://api.opencagedata.com/geocode/v1/json?q=" + LAT + "," + LNG + "&key=703c3f06bf7848f3abfc82bfd1b73e0e&language=en&pretty=1")
        .then(response => response.json())
        .then(data => {
            console.log('city data', data)
            listnerFunc(data)
        }
        )
}
export function getUserMachinePublicIp() {
    return new Promise((resolve, reject) => {
        getPublicIp(resolve)
    })
}
export function getUserMachineLocalIp() {
    return new Promise((resolve, reject) => {
        getLocalIp(resolve)
    })
}
export function getUserCity(data) {
    console.log("city1", data);
    return new Promise((resolve, reject) => {
        getUser_City(data, resolve)
    })
}
export function getCityFromLatLong(lat, lng) {
    console.log("lat", lat, "lng", lng);
    return new Promise((resolve, reject) => {
        get_CityFromLatLong(lat, lng, resolve)
    })
}

export function getBlogs(listnerFunc) {
    return fetch(process.env.REACT_APP_BLOGS_API)
        .then(response => response.json())
        .then(data => {
            console.log('blogs data', data)
            listnerFunc(data)
        }
        )
}

export function getBlogsDetails() {
    return new Promise((resolve, reject) => {
        getBlogs(resolve)
    })
}